.controls {
  display: flex;
  flex-direction: row;
  min-height: 80px;
  background: #242424;
  color: white;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
}

.vlme {
  display: flex;
  align-content: center;
  overflow: hidden;
  width: 25px;
  transition: all 500ms;
  /* position: absolute; */
  left: 0;
}

.vlme:hover {
  width: 90px;
}

.volum {
  padding: 10px;
}

#volBar {
  padding: 0;
  margin: 0;
  width: 50px;
  background: transparent;
}

#volBar::-moz-range-thumb {
  height: 10px;
  width: 3px;
  background: #2a6586;
  border-radius: 5px;
  cursor: pointer;
}

.musicControls {
  margin-left: 90px;
}

.musicControls span {
  cursor: pointer;
}

/* .musicControls span:hover {
  color: #ff8800;
} */

.play {
  background: #0d47a1;
  padding: 15px 18px;
  border-radius: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 3;
}

.webview {
  background: #bf8d2e !important;
}

.play:active {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.play:hover {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.prev {
  background: #4aa9de;
  border-radius: 10px 0 0px 10px;
  padding-left: 15px;
  padding-right: 10px;
  margin-right: -5px;
  z-index: 1;
}

.next {
  background: #4aa9de;
  border-radius: 0px 10px 10px 0px;
  padding-right: 15px;
  padding-left: 10px;
  margin-left: -5px;
  z-index: 1;
}

.progressb {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 490px;
  margin-left: 20px;
}

#prgbar {
  background: transparent;
  width: 350px;
}

.plsoptions {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
}

.plsoptions span {
  cursor: pointer;
}

.plsoptions span:hover {
  color: #2a6586;
}

.plsoptions span.active {
  color: rgb(32, 77, 104);
}

.currentT,
.totalT {
  width: 35px;
}

.plsoptions {
  justify-self: flex-end;
}

@media (max-width: 500px) {
  .logo-footer {
    display: none;
  }

  .volume-container {
    width: 100px !important;
  }

  .vlme {
    margin-right: 10px;
  }

  .title-container {
    font-size: 14px;
  }

  .controls {
    flex-direction: row !important;
  }

  .musicControls {
    margin-left: 0;
  }

  .title {
    padding-top: 0;
  }

  .share-icon {
    margin: 0 !important;
    margin-left: 15px !important;
  }
}

.share-icon {
  margin-left: 15px;
}

.volume-container {
  width: 200px;
}

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0d47a1 !important;
  border: 1px solid #0d47a1 !important;
}

.input-range__track--active {
  background: #0d47a1 !important;
}
