body {
  background: rgba(255, 255, 255, 0.897);
}

#apppage .gradient {
  /* background: -moz-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-gradient(
    linear,
    45deg,
    from(rgba(42, 27, 161, 0.7)),
    to(rgba(29, 210, 177, 0.7))
  );
  background: -o-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  ); */
  background: rgb(4, 33, 89);
  background: linear-gradient(
    90deg,
    rgba(4, 33, 89, 0.45702030812324934) 0%,
    rgba(13, 71, 161, 1) 86%
  );
}

#apppage .view {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/architecture.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

#apppage h6 {
  line-height: 1.7;
}

@media (max-width: 991px) {
  .navbar:not(.top-nav-collapse) {
    background: #0d47a1 !important;
  }

  /* .btn-container {
    flex-direction: column;
  } */

  /* .social-container {
    
    display: flex;
    flex-direction: column;
  } */
}

@media (max-width: 500px) {
  .homePage {
    padding-top: 3rem !important;
  }

  .social-container {
    display: flex;
    flex-direction: column;
  }

  .privacy-page {
    margin-bottom: 55% !important;
  }

  .contact-page {
    margin-bottom: 55% !important;
  }
}

@media (min-width: 768px) {
  .iframeContainer {
    padding-top: 5rem !important;
  }
}

.homePage {
  padding-top: 3rem;
  margin-bottom: 4rem;
}

.iframeContainer {
  padding-top: 4rem;
}

.image-container {
  position: relative;
  background-image: url("/img/twitImage.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 45vh;
  width: 100%;
}

.btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-container {
  padding-top: 0.5rem;
  margin-right: 5px;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.src-components-played-tracks__form-input--3dN88
  .src-components-played-tracks__search-button--1oXRo,
.src-components-played-tracks__buy--1WXSG a {
  /* padding: 0 5px !important;
  margin-left: auto;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(211, 211, 211, 0.5);
  color: #474747;
  text-decoration: none;
  font-size: 14px;
  height: 33px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  font-family: Nunito, sans-serif;
  cursor: pointer; */
  display: none !important;
}

.src-components-played-tracks__form-input--3dN88 {
  display: none !important;
}

.custom {
  width: 100px !important;
  /* text-transform: capitalize; */
}

.content {
  text-transform: capitalize !important;
}

.privacy-page {
  padding-top: 50px;
  margin-bottom: 100px;
}

.contact-page {
  padding-top: 50px;
  margin-bottom: 100px;
}
